#watermark{
	display: none;
}

#watermark .center{
	padding: 10px;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 999;
}

/*Note: using vertical hight to keep size and spacing consistent during HD exports*/
/*Source: https://stackoverflow.com/questions/24469375/keeping-text-size-the-same-on-zooming*/
#watermark h1{
	font-size: 7vh;
	font-weight: 600;
	line-height: 9vh;
	text-align: center;
	text-transform: uppercase;
	color: #F2F2F2;
	margin: 0px;
	opacity: 0.8;
}