/*Top modal*/
.top-modal{
	position: fixed;
	top: -1px;
	left: 50%;
	transform: translateX(-50%) translateY(-100%);
	transition: transform 0.5s, top 0.5s;
	z-index: 7;
}

.top-modal.show{
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.top-modal.show.stick-to-top{
	top: 0px;
	transform: translateX(-50%) translateY(0px);
}

.top-modal.show.menu-slide{
	transform: translateX(calc(-50% + 205px)) translateY(-50%);
}

.top-modal.show.stick-to-top.menu-slide{
	transform: translateX(calc(-50% + 205px)) translateY(0px);
}

/*Grid Container*/
.top-modal .grid-container{
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-content: space-between;
	justify-items: stretch;
	align-items: start;
	grid-column-gap: 25px;
}

.top-modal.increase-left-space .grid-container{
	grid-template-columns: 1.3fr 1fr;
}

.top-modal .grid-container .grid-item.left {
	grid-column-start: 1;
	grid-column-end: 2;
}

.top-modal .grid-container .grid-item.right {
	grid-column-start: 2;
	grid-column-end: 3;
}

.top-modal .grid-container .grid-item.full-height {
	grid-row-start: 1;
	grid-row-end: 3;
}

.top-modal .grid-container .grid-item.full-width {
	grid-column-start: 1;
	grid-column-end: 3;
}

/*Header*/
.top-modal .header.row{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-bottom: 12px;
	color: white;
}

.top-modal .header h1{
	margin: initial;
}

/*Icons*/
.top-modal span.icon{
	width: 50px;
	height: 50px;
	background-position: center;
	background-repeat: no-repeat;
	filter: invert(0%);
}

/*Close button*/
.top-modal .close-button {
	margin-right: -10px;
	margin-top: -12px;
}

/*Center*/
.top-modal .center {
	width: auto;
	padding: 25px 30px;
	background-color: #000;
	border: 2px solid white;
	border-radius: 20px;
}

.top-modal.width-650 .center {
	width: 650px;
}

.top-modal.width-570 .center {
	width: 570px;
}

@media only screen and (max-width: 650px) {
	.top-modal.width-650{
		width: 100%;
	}
	.top-modal.width-650 .center {
		width: 100%;
		min-width: 450px;
	}
}

@media only screen and (max-width: 570px) {
	.top-modal.width-570{
		width: 100%;
	}
	.top-modal.width-570 .center {
		width: 100%;
		min-width: 450px;
	}
}
