/*Source: https://codepen.io/husnimun/pen/pJvEeL*/
.hamburger,
.hamburger:after,
.hamburger:before {
	width: 20px;
	height: 2px;
}

.hamburger {
	position: relative;
	transform: translateY(25px);
	background: black;
	border-radius: 5px;
	transition: all 0ms 300ms;
}
.hamburger.animate {
	background: rgba(255, 255, 255, 0);
}

.hamburger:before {
	content: "";
	position: absolute;
	left: 0;
	bottom: 6px;
	background: black;
	border-radius: 5px;
	transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger:after {
	content: "";
	position: absolute;
	left: 0;
	top: 6px;
	background: black;
	border-radius: 5px;
	transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger.animate:after {
	top: 0;
	background: white;
	transform: rotate(45deg);
	transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger.animate:before {
	bottom: 0;
	background: white;
	transform: rotate(-45deg);
	transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}