/*Images*/
#images {
	/*filter: blur(0px);*/
	/*filter: brightness(400%);*/
	/*filter: invert(100%);*/
	position: absolute;
	will-change: filter;
	overflow: hidden;
	cursor: default;
	display: none;
}

#images.hide-cursor {
	cursor: none !important;
}

#images.draggable {
	cursor: grab;
}

#images.draggable:active {
	cursor: grabbing;
}

/*Images container*/
#images-container {
	position: absolute;
	left: 0px;
	transition: transform 0.3s, left 0.3s;
}

#images-container.menu-slide{
	transform: translateX(205px);
}

/*Img*/
#images img {
	display: block;
	width: 100vw;
	height: 100vh;
	object-fit: contain;
	/*object-fit: scale-down;*/
	/*object-fit: cover;*/
	/*Note: animation causes sound drift;*/
	transition: width 0.3s;
}

#images.waveform-active img {
	width: calc(100vw - 128px); /*Note: subtracted value must match height of waveform in javascript*/
}

/*For animating fantascope*/
/*Note: Using timeout function instead*/
/*#images img {
	-webkit-animation: rotation 1.6s infinite steps(16);
}

@-webkit-keyframes rotation {
	from {
		-webkit-transform: rotate(359deg);
	}
	to {
		-webkit-transform: rotate(0deg);
	}
}*/