/*Form*/
.form form {
	overflow: hidden;
}

/*Header*/
.form h1{
	font-size: 30px;
	font-weight: 700;
	color: #FFF;
	margin:	initial;
}

/*Field*/
.form .field {
	padding-bottom: 18px;
}

/*Label*/
.form .label {
	display: block;
	padding-bottom: 7px;
	font-size: 14px;
	font-weight: 700;
	line-height: normal;
	color: #F2F2F2;
}

.form .field.change .label,
.form .field .label.change{
	color: #99FFAA;
}

.form .label span.asterisk{
	color: red;
	padding-left: 2px;
}

/*Input*/
.form .input {
	display: block;
	margin-top: 0;
	padding: 8px 12px;
	border: none;
	width: 100%;
	border-radius: 5px;
	font-size: 15px;
	opacity: .85;
}

.form .input:focus,.input:active {
	opacity: 1;
	outline: 0;
}

.form .field.change .input {
	background-color: #CCffD5;
}

.form .input.disabled {
	background-color: #757575;
	color:#333;
	opacity: 1;
	cursor: default;
	pointer-events: none;
}

/*Description*/
.form textarea.description {
	min-height: 50px;
	max-height: 100px;
	resize: vertical;
}

/*line*/
.form .line{
	border-bottom: 2px solid #FFF;
	border-radius: 5px;
	opacity: 0.2;
	margin-bottom: 25px;
	margin-top: 10px;
}

/*From buttons*/
.form .form-buttons{
	display: flex;
	justify-content: center;
	gap: 15px;
}

/*Button*/
.form .button {
	display: block;
	width: 100%;
	padding: 10px;
	background-color: #DDD;
	border: none;
	border-radius: 5px;
	font-size: 15px;
	font-weight: 700;
	line-height: 1;
	letter-spacing: normal;
	outline: 0;
	color: #000;
}

.form .button.fixed-width {
	width: 130px;
}

.form .button.auto-width {
	width: auto;
}

.form .button:hover, .button:active, .button:focus {
	cursor: pointer;
}

.form .button:disabled {
	background-color: #666;
	cursor: default;
	color:#333;
}

.form .button#update.change {
	background-color: #99FFAA;
}

/*Bottom buttons*/
.form .bottom-buttons {
	clear: both;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 30px;
}

.form .bottom-buttons p {
	font-size: 15px;
	font-weight: 400;
	color: #999;
	text-decoration: underline;
	white-space: nowrap;
	margin-left: 15px;
	margin-right: 15px;
}

.form .bottom-buttons p:hover, .bottom-buttons p:active, .bottom-buttons p:focus{
	color: #FF7171;
	cursor: pointer;
}

/*Response*/
.form .response {
	display: none;
}

.form .response .message{
	display: flex;
	justify-content: center;
	padding-bottom: 15px;
}

.form .response.post-modal .message{
	padding-top: 20px;
	padding-bottom: 0px;
}

.form .response .message p {
	font-weight: 700;
	font-size: 15px;
	line-height: 1.5;
	/*text-align: center;*/
	color: #05d85a;
}

.form .response.error .message p {
	color: #FFE600;
}
