/*Menu button*/
#menu-button{
	width: 54px;
	height: 54px;
	border-radius: 50%;
	border: 2px solid #000;
	background-color: rgba(255, 255, 255, 0.9);
	display: flex;
	justify-content: center;
	position: fixed;
	top: 10px;
	left: 10px;
	cursor: pointer;
	transition: transform 0.3s;
	transform: translateX(-125%);
	z-index: 4; /*Note: Match side-panel ordering for post page. Change order via javascript when menu is init.*/
}

#menu-button.menu-slide,
#menu-button.show.menu-slide{
	transform: translateX(205px);
}

#menu-button.show{
	transform: translateX(0%);
}

#menu-button.active{
	background-color: rgba(0, 0, 0, 0.9);
	border: 2px solid #FFF;
}

#menu-button.disable{
	opacity: 0.5;
	pointer-events: none;
}

#menu-button.above{
	z-index: 11;
}

/*Note: slight position correction*/
#menu-button .hamburger{
	margin-top: -1px;
}