/*Source: https://github.com/katspaugh/wavesurfer.js/issues/634*/
#waveform{
	transform: translateX(100%) rotate(90deg);
	transform-origin: top left;
	transition: transform 0.3s;
}

/*Note: margin-top value must match height of waveform in javascript*/
#waveform wave{
	margin-top: -128px;
	/*Note: animation causes sound drift;*/
	transition: margin-top 0.3s;
}

#waveform.waveform-active wave{
	margin-top: 0px;
}

#waveform.menu-slide{
	transform: translateX(calc(100% + 205px)) rotate(90deg);
}
