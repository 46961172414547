/* CSS Reset
* --------------------------------------- */
* {
	box-sizing: border-box;
	/*-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;*/
	outline: 0 !important;
	margin: 0px;
	padding: 0px;
	overscroll-behavior-x: none;
}

/* Body and HTML
* --------------------------------------- */
html {
	height:100%;
	/*overflow: hidden;*/
}
body{
	width: 100%;
	height: auto;
	background-color: #000;
	background-attachment: fixed; /*Fixes background color when resizing*/
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	/*overflow-y: auto;*/
	overflow-y: scroll;
	font-family: 'Inter', sans-serif;
}

body.white-background{
	background-color: #F2F2F2;
}

/* Images
* --------------------------------------- */
/*Prevent image drag*/
img {
	-webkit-user-drag: none;
	-moz-user-drag: none;
	user-drag: none;
}

/* Misc
* --------------------------------------- */
/*.avoid-clicks {
	pointer-events: none;
}*/

/* Beta Test
* --------------------------------------- */
.disabled-for-beta{
	opacity: 0.6;
	pointer-events: none;
}
.disabled-for-beta-message{
	font-size: 12.5px;
	font-style: italic;
}

/* Text
* --------------------------------------- */
.whitespace-break{
	white-space: break-spaces;
}