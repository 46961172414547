/*Top panel*/
#top-panel{
	position: fixed;
	top: 10px;
	right: 10px;
  opacity: 0;
	visibility: hidden;
  transition: opacity 0.4s, visibility 0s 0.4s, transform 0.3s;
	transform: translateY(0%);
	display: flex;
	flex-direction: row;
  justify-content: center;
	gap: 3px;
	z-index: 4;
}

#top-panel.menu-slide,
#top-panel.show.menu-slide{
	transform: translateX(205px);
}

#top-panel.show{
	opacity: 1;
	visibility: visible;
	transition: opacity 0.4s, transform 0.3s;
}

#top-panel.hide{
	transform: translateY(-112%);
}

/*Panel*/
#top-panel.panel{
	padding: 10px;
	background-color: rgba(25, 25, 25, 0.8);
	border-radius: 20px;
	border: 2px solid #333333;
}

/*Disable*/
#top-panel .disable{
	opacity: 0.8;
	cursor: default;
	pointer-events: none;
}

#top-panel .disableUI{
	opacity: 0.8;
	cursor: default;
}

#top-panel .item.disable .circle,
#top-panel .item .circle.disable,
#top-panel .item.disableUI .circle{
	background-color: rgba(140, 140, 140, 0.9) !important;
}

/*Text*/
#top-panel p{
	font-weight: 500;
	font-size: 12px;
	text-align: center;
	line-height: 1.3;
	color: #FFF;
}

/*Wrap*/
#top-panel .wrap{
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
  width: 54px;
}

/*Item*/
#top-panel .item{
	width: 54px;
	height: 54px;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
}

#top-panel span.icon.menu{
	width: 50px; /*Note: Item width - (border width * 2) => 54 - (2*2) = 50*/
	height: 50px; /*Note: Item height - (border height * 2) => 54 - (2*2) = 50*/
	background-size: 50px;
	background-position: center;
	background-repeat: no-repeat;
}

#top-panel .item .circle{
	border-radius: 50%;
	border: 2px solid #000;
	background-color: rgba(255, 255, 255, 0.9);
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
}

#top-panel .item.active .menu.circle,
#top-panel .item.active-on-click:active:not(.disableUI) .menu.circle{
	border: 2px solid #FFF;
	background-color: rgba(0, 0, 0, 0.9);
	cursor: default;
}

#top-panel .item.highlight .circle{
	background-color: rgba(80, 255, 151, 0.9);
}

#top-panel .item.active span.menu.icon,
#top-panel .item.active-on-click:active:not(.disableUI) span.menu.icon{
	filter: invert(100%);
}

#top-panel .item:not(.disableUI) span.icon:hover{
	cursor: pointer;
}

/*Share icon*/
#top-panel #share span.menu{
	background-image: url('../../images/post/side-panel/share_50px.svg');
}

/*Save icon*/
#top-panel #save span.menu{
	background-image: url('../../images/post/side-panel/save_50px.svg');
}

/*Export icon*/
#top-panel #export span.menu{
	background-image: url('../../images/post/side-panel/export_50px.svg');
}