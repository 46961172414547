#export-panel{}

/*Text*/
#export-panel p{
	font-weight: 500;
	font-size: 13px;
	line-height: 1;
	white-space: nowrap;
	color: #FFF;
}

#export-panel p.fixed-width{
	width: 115px; /*Note: needed when dynamically changing checkbox text to avoid spacing jump*/
}

/*Hide*/
#export-panel .hide{
	opacity: 0;
	pointer-events: none;
}

/*Settings*/
#export-panel .settings{
	display: flex;
	flex-direction: column;
	gap: 15px;
	padding-top: 5px;
}

#export-panel .settings .container{
	/*width: 100%;*/
	display: flex;
	flex-direction: row-reverse;;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
}

/*Disable*/
#export-panel .disable {
	opacity: 0.4;
	pointer-events: none;
}

/*Duration*/
#export-panel .fixed-font{
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
}

/*Switches*/
#export-panel .switches {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-content: space-between;
	align-items: stretch;
	gap: 2px;
	margin-top: -5px;
	margin-right: -5px;
}

/*Center message*/
#export-panel .center-message {
	position: absolute;
	width: 500px; /*Correct for offset*/
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  height: 50%;
}

#export-panel .center-message p{
	font-size: 14px;
	text-align: center;
	line-height: 1.8;
	white-space: normal;
	max-width: 400px;
}

/*Spinner*/
#export-panel .spinner{
	transform: scale(0.6);
	display: none;
}

/*Icons*/
#export-panel .center-message .icon{
	width: 50px;
	height: 50px;
	background-position: center;
	background-repeat: no-repeat;
	margin-bottom: 20px;
	display: none;
}

/*Success icon*/
#export-panel .center-message .icon.success{
	background-image: url('../../images/post/export_less-padding_50px.svg');
	filter: invert(100%);
}

/*Error icon*/
#export-panel .center-message .icon.error{
	border-radius: 50%;
	background-color: #FFE600;
	background-image: url('../../images/shared/alert_50px.svg');
}
