/*Side panel*/
#side-panel{
	position: fixed;
	top: 10px;
	left: 10px;
	/*Note: width value needed for positioning when display inline-flex is used on tooltips*/
	width: 54px;
	transition: transform 0.3s;
	transform: translateX(-125%);
	visibility: hidden;
	display: flex;
	flex-direction: column;
	gap: 3px;
	z-index: 4;
}

#side-panel.menu-slide,
#side-panel.show.menu-slide{
	transform: translateX(205px);
}

#side-panel.show{
	transform: translateX(0%);
}

/*Disable*/
#side-panel .disable{
	opacity: 0.8;
	cursor: default;
	pointer-events: none;
}

#side-panel .disableUI{
	opacity: 0.8;
	cursor: default;
}

#side-panel .item.disable .circle,
#side-panel .item .circle.disable,
#side-panel .item.disableUI .circle{
	background-color: rgba(140, 140, 140, 0.9) !important;
}

/*Text*/
#side-panel p{
	font-weight: 600;
	font-size: 13px;
	text-align: center;
	line-height: 1.3;
	color: #FFF;
}

/*Item*/
#side-panel .item{
	width: 54px;
	height: 54px;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
}

#side-panel .item.icon-caption-wrap{
	align-items: flex-start;
}

#side-panel .item.active{
	width: auto;
	gap: 10px;
}

#side-panel .item .circle{
	border-radius: 50%;
	border: 2px solid #000;
	background-color: rgba(255, 255, 255, 0.9);
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
}

#side-panel .item.active .menu.circle,
#side-panel .item.active-on-click:active:not(.disableUI) .menu.circle,
#side-panel .item.active .extra .circle:active{
	border: 2px solid #FFF;
	background-color: rgba(0, 0, 0, 0.9);
	cursor: default;
}

#side-panel .item.highlight .circle{
	background-color: rgba(80, 255, 151, 0.9);
}

#side-panel .item.alert .circle,
#side-panel .item.active.alert .circle{
	background-color: rgba(255, 230, 0, 0.9);
	border: 2px solid #000;
}

#side-panel .item .extra p{
	background-color: rgba(0, 0, 0, 0.9);
	padding: 5px;
	border-radius: 5px;
	font-size: 12px;
}

#side-panel .item .extra p.fixed-width{
	width: 95px;
}

#side-panel .item .extra p .audio-limits{
	font-size: 11px;
}

#side-panel .item.active span.menu.icon,
#side-panel .item.active-on-click:active:not(.disableUI) span.menu.icon,
#side-panel .item.active .extra .circle:active span.icon{
	filter: invert(100%);
}

#side-panel .item.active.alert span.menu.icon,
#side-panel .item .extra span.icon{
	filter: invert(0%);
}

#side-panel .item:not(.disableUI) span.icon:hover{
	cursor: pointer;
}

/*Extra*/
#side-panel .item .extra{
	width: 0px;
	display: flex;
	gap: 7px;
	visibility: hidden;
	opacity: 0;
	transform: translateX(-15%);
}

#side-panel .item.active .extra{
	width: auto;
	visibility: visible;
	opacity: 1;
	transform: translateX(0%);
	transition: opacity 0.5s, transform 0.3s;
}

/*Icon caption wrap*/
#side-panel .item.icon-caption-wrap .extra div{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 3px;
	/*width: 54px;*/ /*Space evenly*/
}

/*Menu icons*/
#side-panel span.icon.menu{
	width: 50px; /*Note: Item width - (border width * 2) => 54 - (2*2) = 50*/
	height: 50px; /*Note: Item height - (border height * 2) => 54 - (2*2) = 50*/
	background-size: 50px;
	background-position: center;
	background-repeat: no-repeat;
}

#side-panel span.icon-place-holder-for-menu-button{
	width: 54px;
	height: 54px;
}

/*Extra icons*/
#side-panel .extra span.icon{
	width: 40px;
	height: 40px;
	background-position: center;
	background-repeat: no-repeat;
}

#side-panel .extra span.icon:hover{
	opacity: 0.6;
}

/*Phone connection icon*/
#side-panel #phone-connection span.menu{
	background-image: url('../../images/post/side-panel/qr_50px.svg');
}

#side-panel #phone-connection.on span.menu{
	background-image: url('../../images/post/side-panel/phone-connected_50px.svg');
}

#side-panel #phone-connection.alert span.menu{
	background-image: url('../../images/shared/alert_50px.svg') !important;
}

/*Help icon*/
#side-panel #help span.menu{
	background-image: url('../../images/post/side-panel/help_50px.svg');
}

/*Info icon*/
#side-panel #info span.menu{
	background-image: url('../../images/post/side-panel/info_50px.svg');
}

/*Project info icon*/
#side-panel #project-info span.menu{
	background-image: url('../../images/post/side-panel/project-info_50px.svg');
}

/*Reset icon*/
#side-panel #reset span.menu{
	background-image: url('../../images/post/side-panel/revert_50px.svg');
}

/*Reset saved keyframes icon*/
#side-panel span#reset-saved-keyframes{
	background-image: url('../../images/post/side-panel/remix_40px.svg');
}

/*Reset URL keyframes icon*/
#side-panel span#reset-url-keyframes{
	background-image: url('../../images/post/side-panel/link_40px.svg');
}

/*Share icon*/
#side-panel #share span.menu{
	background-image: url('../../images/post/side-panel/share_50px.svg');
}

/*Share saved keyframes icon*/
#side-panel span#share-saved-keyframes{
	background-image: url('../../images/post/side-panel/remix_40px.svg');
}

/*Share URL keyframes icon*/
#side-panel span#share-url-keyframes{
	background-image: url('../../images/post/side-panel/link_40px.svg');
}

/*Project settings*/
#side-panel #project-settings span.menu{
	background-image: url('../../images/post/side-panel/settings_50px.svg');
}

/*Edit icon*/
#side-panel span#edit{
	background-image: url('../../images/post/side-panel/edit_40px.svg');
}

/*Delete icon*/
#side-panel span#delete-project,
#side-panel span#delete-audio{
	background-image: url('../../images/post/side-panel/delete_40px.svg');
}

/*Audio settings*/
#side-panel #audio-settings span.menu{
	background-image: url('../../images/post/side-panel/audio_50px.svg');
}

#side-panel span#upload-audio{
	background-image: url('../../images/post/side-panel/upload_40px.svg');
}

#side-panel #upload-audio-input{
	display: none;
}

/*Save icon*/
#side-panel #save span.menu{
	background-image: url('../../images/post/side-panel/save_50px.svg');
}

/*Export icon*/
#side-panel #export span.menu{
	background-image: url('../../images/post/side-panel/export_50px.svg');
}

/*Report icon*/
#side-panel #report span.menu{
	background-image: url('../../images/post/side-panel/flag_50px.svg');
}

/*Like icon*/
#side-panel #like span.menu{
	background-image: url('../../images/post/side-panel/heart_50px.svg');
}
#side-panel #like.select span.menu{
	background-image: url('../../images/post/side-panel/heart_filled_50px.svg');
}

/*Remix icon*/
#side-panel #remix span.menu{
	background-image: url('../../images/post/side-panel/remix_50px.svg');
}

/*Tooltip*/
/*Note: inline-flex needed to prevent open panel from blocking controls*/
#side-panel .tooltip{
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	gap: 6px;
}

#side-panel .tooltip-text{
	/*visibility: hidden;
	opacity: 0;*/
	background-color: rgba(0, 0, 0, 0.8);
	border-radius: 10px;
	padding: 10px;
	display: none;
	z-index: 1;
}

#side-panel .tooltip .item:hover + .tooltip-text {
	/*visibility: visible;
	opacity: 1;*/
	display: initial;
}

#side-panel .tooltip .item.active.hide-toolip-on-active:hover + .tooltip-text {
	/*visibility: hidden;
	opacity: 0;*/
	display: none;
}

#side-panel .tooltip-text p{
	font-weight: 600;
	font-size: 13px;
	color: #FFF;
	white-space: nowrap;
	line-height: 1.3;
}

/* Slide when playing */
#side-panel .tooltip{
	visibility: visible;
	transition: transform 0.3s;
}

#side-panel .tooltip.slide{
	transition: transform 0.3s;
	transform: translateX(-65px);
}

#side-panel .tooltip.hide{
  visibility: hidden;
}