/*Element*/
.shepherd-element{
	z-index:9999;
}

/*Modal*/
/*Source: https://cdn.jsdelivr.net/npm/shepherd.js@8.3.1/dist/css/shepherd.css*/
/*Bug: opacity transition causing full opacity*/
.shepherd-modal-overlay-container{
	height: 0;
	left: 0;
	opacity: 0;
	overflow: hidden;
	pointer-events: none;
	position: fixed;
	top: 0;
	/*transition: all .3s ease-out,height 0ms .3s,background-color .3s 0ms;*/
	width: 100vw;
	z-index: 9997;
}.shepherd-modal-overlay-container.shepherd-modal-is-visible{
	height: 100vh;
	opacity: .75;
	/*transition: all .3s ease-out,height 0s 0s,background-color .3s 0s;*/
}.shepherd-modal-overlay-container.shepherd-modal-is-visible path{
	pointer-events: all;
}

/*Highlight*/
.highlight-tour{
	box-shadow: 0px 0px 0px 4px #50ff97;
	border-radius: 10px !important;
	animation: pulse 1.5s infinite;
	animation-direction: alternate;
	z-index: 999;
}

@keyframes pulse {
	0% {
		box-shadow: 0px 0px 0px 2px #50ff97;
	}
	100% {
		box-shadow: 0px 0px 0px 10px #50ff97;
	}
}

/*Center*/
.center-offset{
	min-width: 400px;
	max-width: 530px;
	margin-top: -20px !important;
}

.center-offset .shepherd-content{
	min-width: initial;
	max-width: initial;
}

/*Custom element size*/
.shepherd-element.full-width{
	/*Resize element without padding*/
	/*Note: can't click UI beneath element with full width*/
	width: 100%;	
}

.shepherd-element.long-text{
	max-width: 600px;
}

/*Content*/
.shepherd-content{
	min-width: 400px;
	max-width: 600px;
	background-color: black;
	color: white;
	padding: 30px;
	margin: auto;
	margin-top: 15px;
	margin-bottom: 5px;
	border: 2px solid white;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;
}

/*Header*/
.shepherd-has-title .shepherd-content .shepherd-header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	color: #4fff98;
}

.shepherd-has-title .shepherd-content .shepherd-header .shepherd-title {
	font-size: 25px;
	font-weight: 700;
}

/*Text*/
.shepherd-text {
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	user-select: all;
}

.shepherd-text .small{
	font-size: 13px;
}

.shepherd-text .bold{
	font-weight: 800;
}

/*Icons*/
.shepherd-text .icon{
	display: inline-block;
	background-position: center;
	background-repeat: no-repeat;
	filter: invert(100%);
}

.shepherd-text .icon.size_40{
	width: 35px;
	height: 22px;
	background-size: 40px;
}

.shepherd-text .icon.size_50{
	width: 45px;
	height: 25px;
	background-size: 50px;
}

.shepherd-text .phone-connection{
	background-image: url('../../images/post/side-panel/qr_50px.svg');
}

.shepherd-text .reset-saved-keyframes{
	background-image: url('../../images/post/side-panel/remix_40px.svg');
}

.shepherd-text .reset-url-keyframes{
	background-image: url('../../images/post/side-panel/link_40px.svg');
}

/*Footer*/
.shepherd-footer{
	display: flex;
	justify-content: space-between;	
}

.flex-end .shepherd-footer{
	justify-content: flex-end;
}

.shepherd-footer button{
	background-color: #3e3c3c;
	border: none;
	color: white;
	font-size: 16px;
	font-weight: 600;
	border-radius: 8px;
	padding: 8px;
	cursor: pointer;
}

.shepherd-footer button:hover {
	background-color: #FFF;	
	color: black;
}

/*Close*/
.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
	border: none;
	margin-top: -15px;
	margin-right: -10px;
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon span {
	display: none;
}

/*Line*/
.shepherd-content .line {
	border-bottom: 2px solid #FFF;
	border-radius: 5px;
	opacity: 0.2;
	margin-top: 25px;
	margin-bottom: 10px;
}

/*Arrow*/
/*Source: https://github.com/shipshapecode/shepherd/issues/660*/
/*Source: https://github.com/shipshapecode/shepherd/blob/master/landing/css/styles.css#L107-L237*/
.shepherd-element .shepherd-arrow {
	border-width: 0;
	height: auto;
	width: auto;
	margin-left: 2px;
}

.shepherd-arrow::before {
	display: none;
}

.shepherd-element .shepherd-arrow:after {
	width: 30px;
	content: url('../../images/post/arrow_30px.svg');
	display: inline-block;
	filter: invert(100%);
}

.shepherd-element[data-popper-placement^='top'] .shepherd-arrow,
.shepherd-element.shepherd-pinned-top .shepherd-arrow {
	bottom: -32px;
}

.shepherd-element[data-popper-placement^='top'] .shepherd-arrow:after,
.shepherd-element.shepherd-pinned-top .shepherd-arrow:after {
	transform: rotate(270deg);
}

.shepherd-element[data-popper-placement^='bottom'] .shepherd-arrow {
	top: -32px;
}

.shepherd-element[data-popper-placement^='bottom'] .shepherd-arrow:after {
	transform: rotate(90deg);
}

.shepherd-element[data-popper-placement^='left'] .shepherd-arrow,
.shepherd-element.shepherd-pinned-left .shepherd-arrow {
	right: -30px;
}

.shepherd-element[data-popper-placement^='left'] .shepherd-arrow:after,
.shepherd-element.shepherd-pinned-left .shepherd-arrow:after {
	transform: rotate(180deg);
}

.shepherd-element[data-popper-placement^='right'] .shepherd-arrow,
.shepherd-element.shepherd-pinned-right .shepherd-arrow {
	left: -32px;
}
