#panel-message{
	width: auto;
	background-color: rgba(0, 0, 0, 0.8);
	border-radius: 10px;
	padding: 10px;
	user-select: none;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.4s, visibility 0s 0.4s;
}

#panel-message.show{
	opacity: 1;
	visibility: visible;
	transition: opacity 0.4s;
}

#panel-message p{
	font-weight: 600;
	font-size:13px;
	color: #FFF;
	text-align: center;
	line-height: 1.3;
}