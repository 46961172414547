#project-info-title-card{
	padding: 0px 0 100px 0;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0,0,0,0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 11;
	opacity: 0;
	visibility: hidden;
}

#project-info-title-card .flex-container{
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 10px;
}

/*Text*/
#project-info-title-card .message{
	line-height: 1.6;
	text-align: center;
	color: #FFF;
	padding-right: 60px;
	padding-left: 60px;
	max-width: 700px;
}

#project-info-title-card .message h1{
	font-weight: 600;
	font-size: 25px;
	margin: 0px;
	margin-bottom: 13px;
}

#project-info-title-card .message p{
	font-weight: 400;
	font-size: 18px;
}

#project-info-title-card .message p.description{
	font-size: 14px;
	padding-top: 20px;
	max-width: 550px;
}