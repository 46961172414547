/*Radios*/
.radios {
	user-select: none;
}

.radios .radio {
	margin-bottom: 8px;
}

.radios .radio:last-child {
	margin-bottom: 0px;
}

.radios .field {
	padding-bottom: 0px;
}

.radios .main-label{
	padding-bottom: 12px;
}

.radios .radio-label {
	display: inline-block;
	padding-bottom: 0px;
	white-space: nowrap;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
}

/*Source: https://codepen.io/triss90/pen/XNEdRe*/
.radio {
	/*margin: 15px 0px;*/
}
.radio input[type=radio] {
	position: absolute;
	opacity: 0;
}
.radio input[type=radio] + .radio-label:before {
	content: "";
	background: #f2f2f2;
	border-radius: 100%;
	display: inline-block;
	width: 2em;
	height: 2em;
	position: relative;
	top: -0.3em;
	margin-right: 0.6em;
	vertical-align: top;
	cursor: pointer;
	text-align: center;
	/*transition: all 250ms;*/
}
.radio input[type=radio]:checked + .radio-label:before {
	background-color: #000;
	box-shadow: inset 0 0 0 6px #f2f2f2;
}
.radio input[type=radio]:focus + .radio-label:before {
	border-color: #000;
	outline: none;
}
.radio input[type=radio]:disabled + .radio-label:before {
	background: #999;
	box-shadow: inset 0 0 0 6px #f2f2f2;
}
.radio input[type=radio] + .radio-label:empty:before {
	margin-right: 0;
}