#dat-gui {
	position: fixed;
	top: 0px;
	left: 0px;
	transition: transform 0.3s;
	transform: translateX(-100%);
	z-index: 20;
}

#dat-gui.open{
	transform: translateX(0%);
}