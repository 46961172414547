/*Center modal*/
#center-modal{
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0,0,0,1);
	transition: background 0.5s, transform 0.3s;
	opacity: 1;
	z-index: 9;
}

#center-modal.menu-slide{
	/*Note: offset by 1 pixel to prevent gap;*/
	transform: translateX(204px);
}

#center-modal.init{
	background: rgba(0,0,0,0.8);
}

#center-modal.init.create-mode-options{
	background: rgba(0,0,0,1);
}

/*Wrap*/
#center-modal .wrap{
	padding: 0px 0 40px 0;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

/*Center*/
#center-modal .center {
	padding-left: 20px;
	padding-right: 20px;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
}

/*Content*/
#center-modal .content {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 0;
}

/*Close*/
#center-modal #close-center-modal{
	display: none;
}

/*Icons*/
#center-modal #icons{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 25px;
}

#center-modal #icons .link{
	cursor: pointer;
}

#center-modal #icons .link:hover{
	opacity: 0.8;
}

#center-modal #icons img{
	position: relative;
	width: 150px;
	height: auto;
	margin: auto;
}

#center-modal #icons img.hide{
	display: none !important;
}

#center-modal #icons img.disable{
	opacity: 0.5;
	pointer-events: none;
}

/*QR code wrap*/
#center-modal .qrcode-wrap{
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 76px;
}

#center-modal.create-mode .qrcode-wrap{
	margin-left: 0px;
}

/*QR code*/
#center-modal .qrcode{
	display: none;
}

#center-modal .qrcode img{
	position: relative;
	width: 200px;
	height: auto;
	margin: auto;
	border: 15px solid white;
	border-radius: 10px;
}

/*Mouse icon*/
#center-modal span.mouse{
	width: 350px;
	height: 200px;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('../../images/post/mouse_150px.svg');
	filter: invert(100%);
	cursor: pointer;
}

#center-modal.create-mode span.mouse{
	display: none;
}

#center-modal span.mouse:hover{
	opacity: 0.8;
}

/*Message*/
#center-modal #message{
	padding-top: 40px;
}

#center-modal #message p{
	font-weight: 500;
	font-size: 18px;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: center;
	margin: auto;
	color: #F2F2F2;
}

#center-modal #message p.centerShort{
	max-width: 350px;
}

#center-modal #message p.centerLong{
	max-width: 450px;
}

#center-modal #message p .small{
	font-size: 14px;
}

#center-modal #message p .link{
	text-decoration: underline;
	cursor: pointer;
}

#center-modal #message p .link:hover{
	color: rgba(80, 255, 151, 0.9);
}

/*Reset*/
#center-modal #reset-options{
	position: fixed;
	top: 0px;
	right: 0px;
	width: 60px;
	height: 60px;
	filter: invert(100%);
	background-image: url('../../images/post/revert_60px.svg');
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
	display: none;
}

#center-modal #reset-options:hover{
	opacity: 0.8;
}

/* Misc
* --------------------------------------- */
.avoid-clicks {
	pointer-events: none;
}
