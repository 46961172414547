#audio-context-message{
	background-color: rgba(0, 0, 0, 0.8);
	border-radius: 10px;
	padding: 10px;
	user-select: none;
	display: none;
}

#audio-context-message p{
	font-weight: 600;
	font-size:13px;
	color: #FFF;
	text-align: center;
	line-height: 1.3;
}