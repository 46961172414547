/*Fullscreen spinner*/
#fullscreen-spinner {
	display: none;
}

#fullscreen-spinner .center{
	padding: 10px 0 50px 0;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
}

#fullscreen-spinner .spinner {
	transform: scale(0.6);
}