/*Keyframes*/
#keyframes{
	position: fixed;
	top: 114px;
	right: 10px;
	z-index: 4;
	transition: all 0.3s;
	transform: translateX(120%);
	opacity: 1;
	visibility: hidden;
}

#keyframes.menu-slide,
#keyframes.show.menu-slide{
	transform: translateX(205px);
}

#keyframes.show{
	transform: translateX(0%);
}

#keyframes.slide-up{
	top: 10px;
}

#keyframes.hide{
	opacity: 0;
}

#keyframes.disable{
	opacity: 0.5;
	cursor: default;
	pointer-events: none;
}

/*Text*/
#keyframes p{
	font-weight: 600;
	font-size: 13px;
	text-align: center;
	line-height: 1.3;
	color: #000;
}

/*Items*/
#keyframes #items li{
	list-style-type: none;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-bottom: 3px;
}

/*Bubble*/
#keyframes .bubble{
	display: flex;
	flex-direction: row;
	padding: 5px;
	border-radius: 50px;
	border: 2px solid #000;
	background-color: rgb(230 230 230 / 80%);
	align-items: flex-start;
	height: 54px;
	transition: width 0.3s, height 0.3s, border-radius 2s;
}

#keyframes .bubble .sort-handle{
	cursor: pointer;
}

#keyframes .bubble .sort-handle:active{
	cursor: pointer;
}

#keyframes .add-button .bubble .sort-handle{
	cursor: pointer;
}

#keyframes .bubble .flex-row-container{
	display: flex;
	flex-direction: row;
	align-items: center;
}

/*Bubble expand*/
#keyframes .bubble.expand{
	/* Note: width animation via javascript */
	height: 196px;
	border-radius: 25px;
	transition: width 0.3s, height 0.3s, border-radius 0s;
}

#keyframes .bubble.expand.increase-height{
	height: 232px;
}

#keyframes .bubble.expand .flex-col-container{
	display: flex;
	flex-direction: column;
	gap: 8px;
}

#keyframes .bubble .wrap-expand-controls{
	height: 0px;
	opacity: 0;
	visibility: hidden;
}

#keyframes .bubble.expand .wrap-expand-controls{
	height: auto;
	opacity: 1;
	visibility: visible;
	transition: opacity 0.8s 0.2s;
}

#keyframes .bubble .wrap-expand-controls .expand-controls{
	display: flex;
	flex-direction: column;
	gap: 14px;
	margin-left: 7px;
	margin-right: 7px;
}

#keyframes .bubble .wrap-expand-controls .line {
	height: 2px;
	background-color: black;
	border-radius: 10px;
	opacity: 0.5;
}

#keyframes .bubble .wrap-expand-controls .expand-controls .wrap-input{
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: space-between;
	gap: 15px;
}

#keyframes .bubble .wrap-expand-controls .expand-controls .wrap-input p{
	min-width: 112px;
	text-align: left;
	color: black;
	white-space: nowrap;
}

#keyframes .active .bubble .wrap-expand-controls .expand-controls p{
	color: white;
}

#keyframes .active .bubble .wrap-expand-controls .line {
	background-color: white;
}

/* Icons */
#keyframes .bottom-icons span.icon{
	width: 30px;
	height: 30px;
	background-size: 30px;
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
}

#keyframes .active .bottom-icons span.icon {
	filter: invert(100%);
}

#keyframes .bottom-icons span.icon:hover{
	opacity: 0.8;
}

/* Undo Icon */
#keyframes .bottom-icons span.icon.undo{
	background-image: url('../../images/post/keyframe_revert_30px.svg');
}

/* Redo Icon */
#keyframes .bottom-icons span.icon.redo{
	background-image: url('../../images/post/keyframe_revert_30px.svg');
	transform: scaleX(-1);
}

/* Delete Icon */
#keyframes .bottom-icons span.icon.trashcan{
	background-image: url('../../images/post/delete_30px.svg');
}

/*Fixed font*/
#keyframes .bubble .wrap-expand-controls .expand-controls .wrap-input p .fixed-font{
	font-family: 'Open Sans', sans-serif;
	font-size: 13px;
}

/*Bubble actions*/
#keyframes .bubble.remove,
#keyframes .bubble.remove.increase-height{
	height: 0px;
	padding: 0px;
	border: 0px;
	opacity: 0;
	transition: height 0.3s;
}

#keyframes #items.disable-sort .bubble,
#keyframes #items.disable-sort .bubble .sort-handle{
	cursor: default;
}

#keyframes #items li.chosen .bubble {
	background-color: #50ff97;
}

#keyframes #items li.active .bubble {
	background-color: rgb(50 50 50 / 90%);
	border: 2px solid #FFF;
}

/*Bubble icons*/
#keyframes #items .bubble .icons{
	display: flex;
	flex-direction: row;
	gap: 1px;
	opacity: 0;
	min-height: 40px;
}

#keyframes #items .bubble .icons.show{
	opacity: 1;
	transition: opacity 0.3s;
}

/*Add button*/
#keyframes li.add-button.slide{
	animation-name: slide;
	animation-duration: 0.3s;
}

@keyframes slide {
	from {transform: translateX(100%);}
	to {transform: translateX(0%);}
}

#keyframes li.add-button .bubble{
	width: 54px;
	background-color: rgb(250 250 250 / 100%);
	cursor: pointer;
}

/*Open button*/
#keyframes #items li .bubble span.open-button{
	width: 16px;
	height: 16px;
	position: absolute;
	right: 18px;
	filter: invert(0%);
	transform: rotate(90deg);
	transition: transform 0.3s;
	cursor: pointer;
	background-image: url('../../images/post/keyframe_arrow_16px.svg');
	background-position: center;
	background-repeat: no-repeat;
}

#keyframes #items li.add-button .bubble span.open-button{
	background-image: url('../../images/post/keyframe_add_16px.svg');
	transform: rotate(90deg);
}

#keyframes #items li.active .bubble span.open-button {
	background-image: url('../../images/post/keyframe_add_16px.svg');
	transform: rotate(45deg);
	filter: invert(100%);
}

#keyframes #items #sort .bubble span.open-button:hover{
	opacity: 0.8;
}

/*Circle wrap*/
#keyframes .circle-wrap {
	position: relative;
	width: 40px;
	height: 40px;
}

/*Circle progress rings*/
/*Source: https://css-tricks.com/building-progress-ring-quickly/*/
#keyframes .circle-wrap .progress-ring-circle {
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
}

/*Circle image wrap*/
#keyframes .circle-wrap .image-wrap {
	position: absolute;
	top: 4px;
	left: 4px;
	border-radius: 50%;
	background-color: #FFF;
	width: 32px;
	height: auto;
	display: flex;
}

/*Icons*/
#keyframes span.icon{
	width: 32px;
	height: 32px;
	background-size: 27px;
	background-position: center;
	background-repeat: no-repeat;
}

#keyframes span.speed{
	background-image: url('../../images/post/keyframes/keyframe_speed_32px.svg');
}

#keyframes span.frameRate{
	background-image: url('../../images/post/keyframes/keyframe_frame-rate_32px.svg');
}

#keyframes span.framing{
	background-image: url('../../images/post/keyframes/keyframe_framing_32px.svg');
}

#keyframes span.focus{
	background-image: url('../../images/post/keyframes/keyframe_focus_32px.svg');
}

#keyframes span.brightness{
	background-image: url('../../images/post/keyframes/keyframe_brightness_32px.svg');
}

#keyframes span.saturate{
	background-image: url('../../images/post/keyframes/keyframe_saturate_32px.svg');
}

#keyframes span.contrast{
	background-image: url('../../images/post/keyframes/keyframe_contrast_32px.svg');
}

#keyframes span.hue{
	background-image: url('../../images/post/keyframes/keyframe_hue_32px.svg');
}

/*Tooltip*/
#keyframes .tooltip{
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	gap: 6px;
	/*For sliding add button when toggling play mode*/
	transition: transform 0.3s;
	transform: translateX(0%);
}

#keyframes .tooltip.hide{
	transition: transform 0.5s;
	transform: translateX(120%);
}

#keyframes .tooltip-text{
	background-color: rgba(0, 0, 0, 0.8);
	border-radius: 10px;
	padding: 10px;
	/*visibility: hidden;
	opacity: 0;*/
	display: none;
	z-index: 1;
}

#keyframes .tooltip-text p{
	font-weight: 600;
	font-size: 13px;
	color: #FFF;
	white-space: nowrap;
	line-height: 1.3;
}

#keyframes .tooltip .add-button:hover + .tooltip-text {
	/*visibility: visible;
	opacity: 1;*/
	display: initial;
}


/* Copied from style above and modified for keyframes-copy in param options module */

/*Keyframes Copy*/
#keyframes-copy{
	top: 10px;
	right: 10px;
	z-index: 4;
	opacity: 1;
	min-height: 100px;
	max-height: 250px;
	margin-bottom: -35px;
	transform: scale(.75);
  transform-origin: top right;
}

#keyframes-copy.hide{
	opacity: 0;
}

#keyframes-copy.disable{
	opacity: 0.5;
	cursor: default;
	pointer-events: none;
}

/*Text*/
#keyframes-copy p{
	font-weight: 600;
	font-size: 13px;
	text-align: center;
	line-height: 1.3;
	color: #000;
}

/*Items*/
#keyframes-copy #items li{
	list-style-type: none;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-bottom: 3px;
}

/*Bubble*/
#keyframes-copy .bubble{
	display: flex;
	flex-direction: row;
	padding: 5px;
	border-radius: 50px;
	border: 2px solid #000;
	background-color: rgb(230 230 230 / 80%);
	align-items: flex-start;
	height: 54px;
	cursor: default;
}

#keyframes-copy .bubble .flex-row-container{
	display: flex;
	flex-direction: row;
	align-items: center;
}

/*Bubble expand*/
#keyframes-copy .bubble.expand{
	/* Note: width animation via javascript */
	height: 205px;
	border-radius: 25px;
	transition: width 0.3s, height 0.3s, border-radius 0s;
}

#keyframes-copy .bubble.expand .flex-col-container{
	display: flex;
	flex-direction: column;
	gap: 12px;
}

#keyframes-copy .bubble .wrap-expand-controls{
	height: 0px;
	opacity: 0;
	visibility: hidden;
}

#keyframes-copy .bubble.expand .wrap-expand-controls{
	height: auto;
	opacity: 1;
	visibility: visible;
	transition: opacity 0.8s 0.2s;
}

#keyframes-copy .bubble .wrap-expand-controls .expand-controls{
	display: flex;
	flex-direction: column;
	gap: 14px;
	margin-left: 7px;
	margin-right: 7px;
}

#keyframes-copy .bubble .wrap-expand-controls .line {
	height: 2px;
	background-color: black;
	border-radius: 10px;
	opacity: 0.5;
}

#keyframes-copy .bubble .wrap-expand-controls .line.negative-margin-bottom{
	margin-bottom: -5px;
}

#keyframes-copy .bubble .wrap-expand-controls .expand-controls .wrap-input{
	display: flex;
	flex-direction: row-reverse;
	gap: 15px;
}

#keyframes-copy .bubble .wrap-expand-controls .expand-controls .wrap-input p{
	min-width: 112px;
	text-align: left;
	color: black;
	white-space: nowrap;
}

#keyframes-copy .active .bubble .wrap-expand-controls .expand-controls p{
	color: white;
}

#keyframes-copy .active .bubble .wrap-expand-controls .line {
	background-color: white;
}

/*Fixed font*/
#keyframes-copy .bubble .wrap-expand-controls .expand-controls .wrap-input p .fixed-font{
	font-family: 'Open Sans', sans-serif;
	font-size: 13px;
}

/*Bubble actions*/
#keyframes-copy .bubble.remove{
	height: 0px;
	padding: 0px;
	border: 0px;
	opacity: 0;
	transition: height 0.3s;
}

#keyframes-copy #items li.chosen .bubble {
	background-color: #50ff97;
}

#keyframes-copy #items li.active .bubble {
	background-color: rgb(50 50 50 / 80%);
	border: 2px solid #FFF;
}

/*Bubble icons*/
#keyframes-copy #items .bubble .icons{
	display: flex;
	flex-direction: row;
	gap: 1px;
	opacity: 0;
	min-height: 40px;
}

#keyframes-copy #items .bubble .icons.show{
	opacity: 1;
	transition: opacity 0.3s;
}

#keyframes-copy li.add-button .bubble{
	width: 54px;
	background-color: rgb(250 250 250 / 100%);
}

/*Circle wrap*/
#keyframes-copy .circle-wrap {
	position: relative;
	width: 40px;
	height: 40px;
}

/*Circle progress rings*/
/*Source: https://css-tricks.com/building-progress-ring-quickly/*/
#keyframes-copy .circle-wrap .progress-ring-circle {
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
}

/*Circle image wrap*/
#keyframes-copy .circle-wrap .image-wrap {
	position: absolute;
	top: 4px;
	left: 4px;
	border-radius: 50%;
	background-color: #FFF;
	width: 32px;
	height: auto;
	display: flex;
}

/*Icons*/
#keyframes-copy span.icon{
	width: 32px;
	height: 32px;
	background-size: 27px;
	background-position: center;
	background-repeat: no-repeat;
}

#keyframes-copy span.speed{
	background-image: url('../../images/post/keyframes/keyframe_speed_32px.svg');
}

#keyframes-copy span.frameRate{
	background-image: url('../../images/post/keyframes/keyframe_frame-rate_32px.svg');
}

#keyframes-copy span.framing{
	background-image: url('../../images/post/keyframes/keyframe_framing_32px.svg');
}

#keyframes-copy span.focus{
	background-image: url('../../images/post/keyframes/keyframe_focus_32px.svg');
}

#keyframes-copy span.brightness{
	background-image: url('../../images/post/keyframes/keyframe_brightness_32px.svg');
}

#keyframes-copy span.saturate{
	background-image: url('../../images/post/keyframes/keyframe_saturate_32px.svg');
}

#keyframes-copy span.contrast{
	background-image: url('../../images/post/keyframes/keyframe_contrast_32px.svg');
}

#keyframes-copy span.hue{
	background-image: url('../../images/post/keyframes/keyframe_hue_32px.svg');
}

/*Tooltip*/
#keyframes-copy .tooltip{
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	gap: 6px;
}

#keyframes-copy .tooltip-text{
	background-color: rgba(0, 0, 0, 0.8);
	border-radius: 10px;
	padding: 10px;
	display: none;
	z-index: 1;
}

#keyframes-copy .tooltip-text p{
	font-weight: 600;
	font-size: 13px;
	color: #FFF;
	white-space: nowrap;
	line-height: 1.3;
}

#keyframes-copy .tooltip .add-button:hover + .tooltip-text {
	display: initial;
}