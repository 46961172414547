/*Source: https://codepen.io/nodws/pen/RMRRYK*/

/*Alert*/
#alert {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.8);
	display: none;
	z-index: 100;
}

/*Center*/
#alert .center {
	padding: 10px 0 50px 0;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

/*Spinner*/
#alert .spinner {
	transform: scale(0.6);
	display: none;
}

/*Container*/
.alert-container {
	position: relative;
	width: auto;
	min-width: 300px;
	height: auto;
	margin: auto;
	padding: 35px;
	background: #000;
	border: 2px solid #FFF;
	border-radius: 20px;
	text-align: center;
	overflow: hidden;
}

/*Wrap*/
.alert-wrap{
	display: flex;
	flex-direction: column;
	gap: 20px;
}

/*Text*/
.alert-container h1,
.alert-container p {
	line-height: 1.3;
	text-decoration: none;
	color: #F2F2F2;
}

.alert-container h1{
	font-size: 25px;
	font-weight: 700;
	margin: 0;
}

.alert-container p{
	font-size: 15px;
	font-weight: 500;
}

/*Message*/
.alert-custom-message{
	max-width: 300px;
}

/*Footer*/
.alert-container footer{
	display: flex;
	justify-content: center;
	gap: 12px;
	padding-top: 8px;
}

.alert-container footer .option{
	padding: 12px;
	border-radius: 8px;
	cursor: pointer;
}

.alert-container footer .option p{
	font-weight: 700;
}

.alert-container footer .alert-cancel {
	background: #FFF;
}

.alert-container footer .alert-cancel p{
	color: #000;
}

.alert-container footer .alert-confirm {
	background: #fd4652;
	display: flex;
	align-items: center;
	gap: 8px;
}

.alert-container footer .alert-confirm span.trash {
	width: 20px;
	height: 20px;
	margin-top: -4px;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('../../images/alert/alert_delete_20px.svg');
	filter: invert(100%);
}

/*Close*/
.alert-container .close-button {
	position: absolute;
	top: 2px;
	right: 2px;
}
