#opaque-background {
	background-color: #000;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%; 
	height: 100%;
  transition: background 0.5s, transform 0.3s;
	opacity: 0.8;
	z-index: 7;
  display: none;
}

#opaque-background.menu-slide{
	/*Note: offset by 1 pixel to prevent gap;*/
	transform: translateX(204px);
}