#background-color {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%; 
	height: 100%;
	transition: transform 0.3s;
	z-index: 0;
}

#background-color.menu-slide{
	transform: translateX(205px);
}
