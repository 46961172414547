/*Menu*/
#menu{
	width: 205px;
	height: 100%;
	position: fixed;
	left: 0px;
	top: 0px;
	opacity: 0;
	visibility: visible;
	user-select: none;
	z-index: 0;
}

#menu.behind{
	z-index: -1;
}

#menu.show{
	opacity: 1;
	visibility: visible;
}

/*Non post app content*/
/*Animation source: https://stackoverflow.com/questions/29786230/how-to-create-css3-bounce-effect*/
#non-post-app-content{
	width: 100%;
	height: 100%;
	min-height: 100vh;
	position: relative;
	transition: transform 0.3s;
	/*transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);*/
	background-color: black;
}

.white-background #non-post-app-content{
	background-color: #F2F2F2;
}

#non-post-app-content.absolute-position{
	position: absolute;
}

#non-post-app-content.menu-slide{
	transform: translateX(205px);
}

/*Menu overlay*/
#menu-overlay{
	background-color: rgba(0, 0, 0, 0);
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%; 
	height: 100%;
	visibility: hidden;
	transition: background-color 0.4s, visibility 0s 0.4s, transform 0.3s;
	z-index: 10;
}

#menu-overlay.menu-slide{
	/*Note: offset by 1 pixel to prevent gap;*/
	transform: translateX(204px);
}

#menu-overlay.show{
	background-color: rgba(0, 0, 0, 0.7);
	visibility: visible;
	transition: background-color 0.4s, transform 0.3s;
}

#menu-overlay.show-full{
	background-color: rgba(0, 0, 0, 1);
	visibility: visible;
	transition: background-color 0.6s, transform 0.3s;
}

/*Text*/
#menu h1{
	font-weight: 700;
	font-size: 30px;
	text-align: left;
	line-height: 1.3;
	color: #FFF;
	margin: 0px;
	margin-bottom: 12px;
	cursor: pointer;
}

#menu p{
	font-weight: 700;
	font-size: 18px;
	text-align: left;
	line-height: 1.3;
	color: #FFF;
}

#menu p.uppercase-text{
	text-transform: uppercase;
}

/*Wrap*/
#menu .wrap{
	height: 100%;
	width: 100%;
	background-color: #1A1A1A;
	padding: 15px 20px;
	display: grid;
	/*grid-template-rows: auto 1fr 1fr auto;*/ /*Use for flicker warning*/
	grid-template-rows: auto 1fr auto;
	align-items: start;
	overflow: auto;
	overflow-x: hidden;
}

#menu .wrap .grid-item:nth-of-type(1) {
	grid-column-start: 1;
	grid-column-end: 4;
	grid-row-start: 1;
	grid-row-end: 2;
}

#menu .wrap .grid-item:nth-of-type(2) {
	grid-column-start: 1;
	grid-column-end: 4;
	grid-row-start: 2;
	grid-row-end: 3;
}

#menu .wrap .grid-item:nth-of-type(3) {
	grid-column-start: 1;
	grid-column-end: 4;
	grid-row-start: 3;
	grid-row-end: 4;
}

#menu .wrap .grid-item:nth-of-type(4) {
	grid-column-start: 1;
	grid-column-end: 4;
	grid-row-start: 4;
	grid-row-end: 5;
}

#menu .wrap .grid-item.center{
	display: flex;
	align-items: center;
	height: 100%;	
}

/*line*/
#menu .line{
	border-bottom: 2px solid #FFF;
	border-radius: 5px;
	margin-bottom: 15px;
	opacity: 0.2;
}

/*List items*/
#menu ul{
	list-style-type: none;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 0px -10px;
}

#menu ul li{
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	padding: 5px;
	border-radius: 8px;
}

#menu ul li.active{
	background-color: #FFF;
	pointer-events: none;
}

#menu ul li.enable-click{
	pointer-events: initial !important;
}

#menu ul li.active p{
	color: #000;
}

#menu ul li.active span{
	filter: invert(0%);
}

#menu ul li:hover{
	background-color: #333333;
	cursor: pointer;
}

#menu ul li.enable-click:hover{
	background-color: rgba(80, 255, 151, 0.9);
	cursor: pointer;
}

/*Flicker warning*/
#menu #flicker-warning p{
	font-size: 13px;
	font-weight: 500;
	color: #a4a4a4;
}

/*Menu footer*/
#menu #menu-footer p{
	font-size: 14px;
}

/*Icons*/
#menu span.icon{
	width: 25px;
	height: 25px;
	background-position: center;
	background-repeat: no-repeat;
	filter: invert(100%);
}

/*Custom icons*/
#menu #explore span{
	background-image: url('../../images/menu/nav_explore_25px.svg');
}
#menu #create span{
	background-image: url('../../images/menu/nav_create_25px.svg');
}
#menu #screen span{
	background-image: url('../../images/menu/nav_screen_25px.svg');
}
#menu #upload span{
	background-image: url('../../images/menu/nav_upload_25px.svg');
}
#menu #my-work span{
	background-image: url('../../images/menu/nav_my-work_25px.svg');
}
#menu #exports span{
	background-image: url('../../images/menu/nav_exports_25px.svg');
}
#menu #settings span{
	background-image: url('../../images/menu/nav_profile_25px.svg');
}
#menu #login span{
	background-image: url('../../images/menu/nav_log-in_25px.svg');
}
#menu #signup span{
	background-image: url('../../images/menu/nav_sign-up_25px.svg');
}
#menu #logout span{
	background-image: url('../../images/menu/nav_log-out_25px.svg');
}
#menu #about span{
	background-image: url('../../images/menu/nav_about_25px.svg');
}
#menu #faq span{
	background-image: url('../../images/menu/nav_help_25px.svg');
}
#menu #contact span{
	background-image: url('../../images/menu/nav_contact_25px.svg');
}
#menu #beta-test span{
	background-image: url('../../images/menu/nav_list_25px.svg');
}
#menu #terms-of-service span{
	background-image: url('../../images/menu/nav_paper_25px.svg');
}
#menu #privacy-policy span{
	background-image: url('../../images/menu/nav_lock_25px.svg');
}
#menu #cookies span{
	background-image: url('../../images/menu/nav_cookie_25px.svg');
}
#menu #guide span{
	background-image: url('../../images/menu/nav_wolf_25px.svg');
}
#menu #likes span{
	background-image: url('../../images/menu/nav_heart_25px.svg');
}
#menu #subscribe span{
	background-image: url('../../images/menu/nav_fire_25px.svg');
}
#menu #remixes span{
	background-image: url('../../images/menu/nav_remix_25px.svg');
}