#messages{
	position: fixed;
	top: 10px;
	left: 50%;
	transform: translate(-50%, 0%);
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	transition: transform 0.3s;
	z-index: 6;
}

#messages.menu-slide{
	transform: translateX(90px);
}