#share-panel{}

/*Flex wrap*/
#share-panel .flex-container{
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
}

/*Text*/
#share-panel p{
	font-weight: 500;
	font-size: 13px;
	line-height: 1.5;
	color: #FFF;
}

/*Switches*/
#share-panel .switch-container {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: flex-end;
	gap: 8px;
}
