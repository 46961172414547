/*Title card*/
/*Note: must place flex within component element for smooth fading with opacity*/
#title-card{
	padding: 10px 0 10px 0;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 11;
	opacity: 0;
	visibility: hidden;
}

#title-card.bottomOffset{
	padding: 10px 0 20px 0;
}

/*Text*/
/*Note: using vertical hight to keep size and spacing consistent during HD exports*/
/*Source: https://stackoverflow.com/questions/24469375/keeping-text-size-the-same-on-zooming*/
#title-card .message{
	/*line-height: 1.5;*/
	line-height: 5vh;
	text-align: center;
	color: #FFF;
}

#title-card.screen-page .message{
	line-height: 1.5;
}

#title-card .message h1{
	font-weight: 600;
	/*font-size: 25px;*/
	font-size: 5.3vh;
	margin: 0px;
	/*margin-bottom: 10px;*/
	margin-bottom: 4vh;
}

#title-card.screen-page .message h1{
	font-size: 30px;
	margin-bottom: 10px;
}

#title-card .message p{
	font-weight: 400;
	/*font-size: 15px;*/
	font-size: 3.1vh;
}

#title-card.screen-page .message p{
	font-size: 20px;
}

#title-card .message p.remix-author{
	font-size: 3vh;
	/*margin-top: 30px;*/
	margin-top: 5.5vh;
}

#title-card.screen-page .message p.remix-author{
	font-size: 20px;
	margin-top: 30px;
}