/*Source: https://codepen.io/josemoreira/pen/MqZjGO*/
/*Loader*/
/*Note: custom loader style used for post and upload routes*/
#loader{
	/*Note: Open Sans being used because variable font is causing width jumping;*/
	font-family: 'Open Sans', sans-serif;
}
#loader .wrap{
	position: relative;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

/*Message*/
#loader-message {
	position: relative;
	width: auto;
	height: auto;
	padding-top:20px;
	padding-bottom:5px;
}
#loader-message p {
	font-size:15px;
	font-weight: 700;
	text-align: center;
	color: #F2F2F2;
}

/*Cancel button*/
#cancel-button:hover {
	opacity: 0.8;
	cursor: pointer;
}
#cancel-button.avoid-clicks {
	pointer-events: none;
}
#cancel-button img {
	display: block;
	width: 100%;
	height: auto;
}

/*Film reel loader*/
#film-reel-loader {
	position: relative;
	width: auto;
	height: auto;
}
#film-reel-loader .tape {
	border-radius: 50%;
	background-color: white;
	width: 100px;
	height: 100px;
	position: relative;
	-webkit-animation: spin 2.5s linear infinite;
	animation: spin 2.5s linear infinite;
}
#film-reel-loader .tape .dot {
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	position: absolute;
	background-color: black;
	border-radius: 50%;
	width: 8px;
	height: 8px;
}
#film-reel-loader .tape .roll {
	background-color: black;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	position: absolute;
}
#film-reel-loader .tape .roll:nth-child(1) {
	left: 7%;
	top: 50%;
	-webkit-transform: translatey(-50%);
	transform: translatey(-50%);
}
#film-reel-loader .tape .roll:nth-child(2) {
	left: 50%;
	bottom: 7%;
	-webkit-transform: translatex(-50%);
	transform: translatex(-50%);
}
#film-reel-loader .tape .roll:nth-child(3) {
	left: 50%;
	top: 7%;
	-webkit-transform: translatex(-50%);
	transform: translatex(-50%);
}
#film-reel-loader .tape .roll:nth-child(4) {
	right: 7%;
	top: 50%;
	-webkit-transform: translatey(-50%);
	transform: translatey(-50%);
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}