#remix-panel .absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*Icons*/
#remix-panel .icon{
	width: 50px;
	height: 50px;
	background-position: center;
	background-repeat: no-repeat;
}

/*Success icon*/
#remix-panel .remix{
	background-image: url('../../images/post/side-panel/remix_50px.svg');
	filter: invert(100%);
}