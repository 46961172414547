#back {
	position: fixed;
	top: 10px;
	left: 10px;
	display: none;
	z-index: 11;
}

#back .circle {
	width: 54px;
	height: 54px;
	border: 2px solid #000;
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 50%;
	display: flex;
	justify-content: center;
}

#back .circle span.icon{
	width: 50px;
	height: 50px;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('../../images/post/left_50px.svg');
}

#back .circle:active{
	border: 2px solid #FFF;
	background-color: rgba(0, 0, 0, 0.9);
}

#back .circle:active span.icon{
	filter: invert(100%);
}

#back .circle:hover {
	cursor: pointer;
}