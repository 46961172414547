/*Source: https://codepen.io/AllThingsSmitty/pen/MmxxOz*/
.switches .switch {
	display: inline-block;
	height: 34px;
	position: relative;
	width: 60px;
}

.switches.small .switch {
	transform: scale(0.8);
}

.switches .switch input {
	display:none;
}

.switches .slider {
	background-color: #FFF;
	box-shadow:0px 0px 0px 2px black;
	bottom: 0;
	cursor: pointer;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: .3s;
}

.switches .slider.no-border {
	box-shadow: none;
}

.switches .slider:before {
	background-color: #717171;
	bottom: 4px;
	content: "";
	height: 26px;
	left: 4px;
	position: absolute;
	transition: .3s;
	width: 26px;
}

.switches input:checked + .slider {
	background-color: #FFF;
}

.switches input:checked + .slider:before {
	transform: translateX(26px);
	background-color: #000;
}

.switches .slider.round {
	border-radius: 34px;
}

.switches .slider.round:before {
	border-radius: 50%;
}