/*Fantascope form items*/
#fantascope-form-items {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	display: none;
}

#fantascope-form-items .column {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

/*Text*/
#fantascope-form-items p,
#remix-license-message p,
#permission-message p{
	font-weight: 600;
	font-size: 12px;
	text-align: left;
	line-height: 1.3;
	color: #FF7171;
}

#remix-license-message{
	display: none;
}

#message-wrap{
	display: flex;
	flex-direction: column;
	gap: 15px;
}

/*Links*/
a:link, a:visited {
	color: #FF7171;
	text-decoration: underline;
}

a:link:hover, a:visited:hover, a:link:active, a:visited:active {
	color: #FF7171;
}

/*Wedge counter*/
/*Source: https://codepen.io/vicodin/pen/VLOMZa*/
#wedge-counter{
	font-family: 'Open Sans', sans-serif;
	user-select: none;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

#wedge-counter .counter {
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

#wedge-counter .circle {
	width: 30px;
	height: 30px;
	box-shadow: 0px 0px 0px 2px #F2F2F2;
	background-color: black;
	border-radius: 50%;
	margin-top: 2px;
	margin-left: 2px;
}

#wedge-counter input.icon {
	width: 30px;
	height: 30px;
	background-position: center;
	background-repeat: no-repeat;
	filter: invert(100%);
	border: none;
	background-color: transparent;
	cursor: pointer;
}

#wedge-counter input#plus {
	background-image: url('../../images/edit/plus_30px.svg');
}

#wedge-counter input#minus {
	background-image: url('../../images/edit/minus_30px.svg');
}

#wedge-counter .disable {
	opacity: 0.5;
	pointer-events: none;
}

#wedge-counter #count {
	width: 55px;
	margin-top: -10px;
	font-size: 35px;
	font-weight: 600;
	text-align: center;
	color: white;
}

/*Color picker*/
/*Source: https://stackoverflow.com/questions/47971289/input-type-color-styling/47971607*/
#color-picker input{
	height: 30px;
	width: 30px;
	box-shadow: 0px 0px 0px 2px #F2F2F2;
	border-radius: 50%;
	margin-top: 2px;
	margin-left: 2px;
	border: none;
	cursor: pointer;
}
#color-picker input::-webkit-color-swatch-wrapper {
	padding: 0; 
}
#color-picker input::-webkit-color-swatch {
	border: none;
	border-radius: 50%;
}