/*Loader*/
/*Note: must place flex within component element for smooth fading with opacity*/
#loader{
	padding: 10px 0 10px 0;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 11;
	opacity: 0;
	visibility: hidden;
}
