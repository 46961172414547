#center-message{
	display: none;
}

#center-message .center{
	padding: 10px 0 40px 0;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

#center-message p{
	font-size: 18px;
	font-weight: 500;
	color: #FFF;
	text-align: center;
	line-height: 1.5;
	width: 100%;
	max-width: 500px;
	padding: 20px;
	margin: auto;
}

#center-message p.hover:hover{
	cursor: pointer;
}

#center-message a {
	text-decoration: none;
}