#stats {
	position: fixed;
	bottom: 0px;
	left: 0px;
	transition: transform 0.3s;
	transform: translateY(100%);
	z-index: 20;
}

#stats.open{
	transform: translateY(0%);
}