/*Source: https://www.w3schools.com/howto/howto_js_rangeslider.asp*/
.range-slider-container {
	width: 100%;
}

.range-slider-container.fixed-width {
	width: 100px;
}

.range-slider {
	-webkit-appearance: none;
	width: 100%;
	height: 15px;
	border-radius: 30px;
	background: #FFF;
	outline: none;
	-webkit-transition: .2s;
	transition: opacity .2s;
}

/*Override default*/
.range-slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 25px;
	height: 25px;
	border-radius: 50%; 
	background: #717171;	
	cursor: pointer;
}

.range-slider::-moz-range-thumb {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background: #717171;
	cursor: pointer;
}

/*Option for black circle*/
.range-slider.black-circle::-webkit-slider-thumb{
	background: #000;
	border: 2px solid #FFF;
}
.range-slider.black-circle::-moz-range-thumb{
	background: #000;
	border: 2px solid #FFF;
}

/*Left slider offset*/
.range-slider.left-slider-offset::-webkit-slider-thumb {
	margin-left: 8px;
}

.range-slider.left-slider-offset::-moz-range-thumb {
	margin-left: 8px;
}