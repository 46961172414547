/*Controls*/
/*To do: make generic circle class without width or height and share between play controls, menu options, side panel, mobile remote, and screen page controls*/
#controls {
	position: fixed;
	bottom: 10px;
	left: 10px;
	width: 100%;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.4s, visibility 0s 0.4s, transform 0.3s;
	z-index: 3;
}

#controls.menu-slide{
	transform: translateX(205px);
}

#controls.show{
	opacity: 1;
	visibility: visible;
	transition: opacity 0.4s, transform 0.3s;
}

#controls.disable,
#controls .disable{
	opacity: 0.5;
	cursor: default;
	pointer-events: none;
}

/*Wrap*/
#controls .wrap{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
}

/*Text*/
#controls p{
	font-weight: 500;
	font-size: 12px;
	text-transform: uppercase;
	text-align: center;
	line-height: 1.3;
	white-space: nowrap;
	color: #FFF;
}

/*Panel*/
#controls .panel{
	padding: 10px;
	background-color: rgba(25, 25, 25, 0.8);
	border-radius: 20px;
	border: 2px solid #333333;
}

/*Caption*/
#controls .caption{
	padding: 2px;
	background-color: rgba(0, 0, 0, 0.8);
	border-radius: 10px;
}

/*Left controls*/
#controls #left-controls{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 5px;
	transition: margin 1s;
}

/*Frame counter*/
/*Degree counter*/
#controls #frame-counter,
#controls #degree-counter{
	/*Note: Open Sans being used because variable font is causing width jumping;*/
	font-family: 'Open Sans', sans-serif;
	/*width: 100px;*/
	width: auto;
	padding: 6px;
	transition: opacity 0.4s;
}

#controls #frame-counter.hide,
#controls #degree-counter.hide{
	opacity: 0;
}

#controls #frame-counter p,
#controls #degree-counter p{
	font-size: 15px;
	font-weight: 600;
}

/*Center controls*/
#controls #center-controls{
	width: 100%;
	display: flex;
	justify-content: center;
	z-index: 1;
}

#controls #center-controls.slide{
	transition: transform;
	transition-duration: 0.4s;
	transform: translateY(120%);
}

#controls #center-controls.show {
	transform: translateY(0%);
}

/*Note: need to define min-width for horizontal sticky content when resizing browser width*/
#controls #center-controls.live-mode{
	min-width: 615px;
}

#controls #center-controls.film{
	min-width: 380px;
}

/*Icons*/
#controls span.icon{
	width: 50px;
	height: 50px;
	background-position: center;
	background-repeat: no-repeat;
	filter: invert(100%);
	cursor: pointer;
}

#controls span.icon.hover:hover{
	opacity: 0.8;
}

/*Playback icon*/
#controls #playback{
	position: relative;
	background-color: rgb(230 230 230 / 80%);
	padding: 5px;
	border: 2px solid black;
	border-radius: 50%;
	cursor: pointer;
	z-index: 10;
}

#controls #playback.disablePlayback,
#controls #playback.disablePlayback span.icon{
	cursor: default;
}

#controls #playback .circle{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 85px;
	height: 85px;
	border-radius: 50%;
	background-color: white;
}

#controls #playback.playing .circle{
	background-color: black;
}

#controls #playback span{
	width: 85px;
	height: 85px;
	background-image: url('../../images/post/play_85px.svg');
	filter: invert(0%);
}

#controls #playback.playing span{
	background-image: url('../../images/post/pause_85px.svg');
	filter: invert(100%);
}

#controls #playback.disablePlayback span{
	opacity: 0.25;
}

/*Shuffle icon*/
#shuffle span{
	background-image: url('../../images/post/shuffle_50px.svg');
}

/*Up icon*/
#frameline-options #up span{
	background-image: url('../../images/post/up_50px.svg');
}

/*Down icon*/
#frameline-options #down span{
	background-image: url('../../images/post/down_50px.svg');
}

/*Reset icon*/
#frameline-options #reset-frame span{
	background-image: url('../../images/post/revert_50px.svg');
}

/*Play controls*/
#play-controls{
	display: flex;
	flex-direction: row;
	gap: 35px;
}

#play-controls.panel{
	padding: 10px 12px;
}

#play-controls .child{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
}

#play-controls .circle {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	background-color: #FFF;
	display: flex;
	align-items: center;
	box-shadow: 0px 0px 0px 2px black;
	cursor: pointer;
}

#play-controls .circle span{
	filter: invert(0%);
}

#play-controls .active .circle,
#play-controls .circle:active{
	box-shadow: 0px 0px 0px 2px white;
	background-color: rgba(0, 0, 0, 0.9);
}

#play-controls .active .circle span,
#play-controls .circle:active span{
	filter: invert(100%);
}

/*Frameline options*/
#frameline-options #frameline-wrap-icons{
	display: flex;
	flex-direction: row;
	gap: 15px;
}

/*Live controls*/
#live-controls{
	display: flex;
	margin-left: 30px; /*Note: offset reset params width;*/
}

/*Reset button*/
.reset-button{
	display: flex;
}

.reset-button .circle{
	width: 30px;
	height: 30px;
	border-radius: 50%;
	box-shadow: 0px 0px 0px 2px black;
	background-color: rgba(255, 255, 255, 0.9);
	display: flex;
	align-items: center;
}

.reset-button .circle span{
	width: 30px;
	height: 30px;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('../../images/post/revert_30px.svg');
	border-radius: 50%;
	cursor: pointer;
}

.reset-button .circle:active{
	box-shadow: 0px 0px 0px 2px white;
	background-color: rgba(0, 0, 0, 0.9);
}

.reset-button .circle:active span{
	filter: invert(100%);
}

/*Menu options*/
/*Note: style adapted from mobile remote menu options*/
/*Source:
https://stackoverflow.com/questions/22252472/how-to-change-the-color-of-an-svg-element
https://codepen.io/sosuke/pen/Pjoqqp*/
#menu-options {
	width: auto;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

#menu-options .panel{
	padding: 10px 0px;
}

/*Param info*/
#menu-options #param-info{
	display: flex;
	flex-direction: row;
	justify-content: center;
	transition: opacity 0.4s;
}

#menu-options #param-info.hide {
	opacity: 0;
}

#menu-options #param-info div{
	width: 75px;
	display: flex;
	justify-content: center;
}

#menu-options #param-info p{
	background-color: rgba(0, 0, 0, 0.8);
	padding: 2px 4px;
	border-radius: 5px;
	width: auto;
	font-size: 12px;
	color: white;
}

/*Figures*/
#menu-options #figures{
	display: flex;
	flex-direction: row;
	justify-content: center;
}

#menu-options figure {
	width: 75px; /*Space circles evenly*/
	/*width: auto;*/ /*Space text evenly*/
	margin: 0;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
}

#menu-options figure .circle {
	border-radius: 50%;
	background-color: #FFF;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 2px;
	box-shadow: 0px 0px 0px 2px black;
	cursor: pointer;
}

#menu-options figure .circle span{
	width: 50px;
	height: 50px;
	filter: invert(0%);
}

/*Select state*/
#menu-options figure.select .circle{
	box-shadow: 0px 0px 0px 2px white;
	background-color: #000;
}

#menu-options figure.select .circle span{	
	filter: invert(100%);
}

/*Small data select state*/
#menu-options.small-data figure.select .circle{
	box-shadow: 0px 0px 0px 2px #f3413e;
}

/*Reset param state*/
#menu-options figure.reset-param .circle {
	box-shadow: 0px 0px 0px 2px #50ff97;
}

/*Reset param select state*/
#menu-options figure.reset-param.select .circle{
	box-shadow: 0px 0px 0px 2px #50ff97;
}

/*Icons*/
#menu-options span.speed{
	background-image: url('../../images/post/params/speed_50px.svg');
}

#menu-options span.frameRate{
	background-image: url('../../images/post/params/frame-rate_50px.svg');
}

#menu-options span.framing{
	background-image: url('../../images/post/params/framing_50px.svg');
}

#menu-options span.focus{
	background-image: url('../../images/post/params/focus_50px.svg');
}

#menu-options span.brightness{
	background-image: url('../../images/post/params/brightness_50px.svg');
}

#menu-options span.saturate{
	background-image: url('../../images/post/params/saturate_50px.svg');
}

#menu-options span.contrast{
	background-image: url('../../images/post/params/contrast_50px.svg');
}

#menu-options span.hue{
	background-image: url('../../images/post/params/hue_50px.svg');
}

/*Audio panel*/
#audio-panel {
	margin-right: 20px;
}

/*Image controls*/
#image-controls {
	margin-right: 20px;
	display: flex;
	flex-direction: row;
	gap: 3px;
}

#image-controls .panel{
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	gap: 15px;
}

#image-controls .container{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
}

#image-controls .reset-button{
	transform: translate(0px, -20px);
}

/*Switches*/
#audio-panel .switches{
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	gap: 10px;
}

#audio-panel .switches .switch-container,
#image-controls .switches .switch-container{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 3px;
}

/*Tooltip*/
#center-controls .tooltip{
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	gap: 8px;
}

#center-controls .tooltip-text{
	background-color: rgba(0, 0, 0, 0.8);
	border-radius: 10px;
	padding: 10px;
	z-index: 1;
}

#center-controls .tooltip-text p{
	font-weight: 600;
	font-size: 13px;
	color: #FFF;
	white-space: nowrap;
	line-height: 1.3;
	text-transform: initial;
}